body {
    height:100%;
    overflow:hidden;
    margin:0;
    padding:0;
    font-family:Roboto, Helvetica, Arial, sans-serif;
    font-size:16px;
    color: #646464;
    background-color:#fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	--mdc-theme-primary: #646464;
    --mdc-theme-secondary: #2f53a7;
	--mdc-theme-secondary-translucent: #e5f3eb;
	--mdc-theme-error: #b71c1c;
}

html {
    height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#krPanoiFrame {
    -webkit-box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 38px 2px rgba(0,0,0,0.75);
}
